html,
body {
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    background-color: #f5f5f5;
    overflow-x: hidden;
}

h2,
h3,
h4 {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.logo {
    max-width: 200px;
}
.bg-progress {
    background: rgb(255, 45, 78);
    background: linear-gradient(
        132deg,
        rgba(255, 45, 78, 1) 0%,
        rgba(9, 9, 121, 1) 58%,
        rgba(0, 212, 255, 1) 120%
    );
}
.intro-panel {
    background: rgb(255, 45, 78);
    background: linear-gradient(
        132deg,
        rgba(255, 45, 78, 1) 0%,
        rgba(9, 9, 121, 1) 58%,
        rgba(0, 212, 255, 1) 100%
    );
}
@media (min-width: 768px) {
    .intro-panel::before {
        position: absolute;
        content: ' ';
        display: block;
        left: -5%;
        top: 585px;
        transform: rotate(-11deg);
        width: 120%;
        background-color: #fff;
        height: 550px;
    }
}

.intro-panel .content {
    max-width: 1100px;
    margin: 0px auto;
    padding-top: 30px;
}

.intro-panel p {
    margin-bottom: 35px;
}

.intro-row {
    padding: 20px 10px 0px;
    margin: 60px 0px 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 768px) {
    .intro-row {
        padding: 200px 30px 0px;
    }
}

.cover-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
}

.cover {
    padding: 0 1.5rem;
    color: #fff;
}

.cover-heading {
    font-size: 2.8rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 3.2rem;
    padding-bottom: 20px;
}

@media (min-width: 448px) {
    .cover-heading {
        font-size: 3.5rem;
        line-height: 4.2rem;
    }
}

@media (min-width: 1024px) {
    .cover-heading {
        font-size: 4.5rem;
        line-height: 5.2rem;
    }
}

.connect-btn {
    padding: 0px;
}

.connect-btn:hover {
    opacity: 0.9;
    border: 1px solid #fff;
    cursor: pointer;
}

.image-container {
    margin-top: 0px;
    display: none;
}

@media (min-width: 768px) {
    .image-container {
        margin-top: -150px;
        padding-bottom: 60px;
        display: block;
    }
}

.image-secondary {
    display: block;
    text-align: center;
}

@media (min-width: 768px) {
    .image-secondary {
        display: none;
    }
}

.mockup {
    max-height: 600px;
    width: auto;
}

.inner {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 448px) {
    .inner {
        display: flex;
        flex-direction: row;
    }
}

.intro-row h2,
h2 {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    max-width: 420px;
}

.intro-row p,
p {
    font-family: 'Inconsolata', monospace;
    font-weight: 300;
    font-size: 1.1rem;
}
.secondary p b {
    font-weight: 700;
}

.secondary p {
    font-size: 0.9rem;
}

.row {
    padding: 60px 20px;
    background-color: #fff;
}

@media (min-width: 768px) {
    .row {
        padding: 60px 0px;
    }
}

.dashboard {
    background-color: #f5f5f5;
}

.padding-remove {
    padding: 0px;
}

.padding-minimal {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-add {
    padding-bottom: 120px;
}

.secondary {
    background-color: #f5f5f5;
    padding-top: 50px;
    padding-bottom: 50px;
}
.footer {
    background-color: #333;
    position: relative;
    height: 100px;
    text-align: center;
    margin-top: 60px;
}

@media (min-width: 768px) {
    .footer::after {
        position: absolute;
        z-index: 1;
        content: ' ';
        display: block;
        left: -5%;
        top: -30px;
        transform: rotate(-2deg);
        width: 120%;
        background-color: #333;
        height: 60px;
    }
}

.footer-inner {
    padding-top: 0px;
    padding-bottom: 20px;
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #333;
}

@media (min-width: 720px) {
    .footer-inner {
        padding-top: 30px;
        display: flex;
        flex-direction: row;
    }
}

.footer-item {
    padding: 5px;
}

@media (min-width: 720px) {
    .footer-item {
        padding: 20px;
    }
}

.privacy p {
    font-size: 0.9rem;
}
.privacy h4 {
    font-size: 1.2rem;
    width: 100%;
}

.btn-modal {
    float: none;
    background: #000;
    padding: 10px;
    color: #fff;
    min-width: 150px;
    border: 1px solid #000;
    margin: 5px;
}

.btn-modal:hover {
    opacity: 0.8;
    border: 1px solid #fff;
}

.settings-btn {
    background-color: #fff;
    opacity: 0.5;
    border-radius: 15px;
    height: 30px;
}

.settings-btn-text {
    color: #fff;
}

.nav-btn {
    color: #fff;
    padding-left: 30px;
}
.nav-btn:hover {
    text-decoration: underline;
    color: #fff;
}
.alert {
    margin-top: 10px;
}

.alert-success a {
    color: #155724;
    text-decoration: underline;
}

.alert-success a:hover {
    color: #155724;
    text-decoration: none;
}

.container {
    padding-top: 60px;
}

.hidden {
    display: none;
}

.btn-outline {
    border-color: #fff;
    color: #fff;
    margin: 10px;
}

.btn-outline:hover {
    border-color: #3d5170;
    background-color: #3d5170;
    color: #fff;
    margin: 10px;
}

.btn-group > .btn {
    padding: 0.4286rem 0.875rem;
    font-size: 0.6875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    border: 1px solid #e1e5eb;
    color: #3d5170;
    background-color: #fff;
}
.btn-group > .active {
    color: #fff;
    background-color: #3d5170;
}

.card {
    margin: 10px 0px;
    width: 100%;
    border-radius: 0.625rem;
    border: none;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
        0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
        0 7px 70px rgba(90, 97, 105, 0.1);
    padding: 10px;
}

.h5 {
    font-size: 0.8rem;
}

.card-body {
    font-family: 'Inconsolata', monospace;
    font-weight: 300;
    font-size: 1.1rem;
}

.card-header {
    font-size: 1rem;
    background-color: #fff;
    border-radius: 0.625rem;
    font-weight: 600;
}

.card a {
    font-weight: normal;
}

.card a:hover {
    color: #007bff;
}

.activity-button {
    color: #3d5170;
    background: none;
    padding: 5px 0px;
    font-size: 0.8rem;
    border: none;
    display: block;
    text-decoration: underline;
}

@media (min-width: 720px) {
    .activity-button {
        float: right;
        padding-left: 10px;
    }
}

.activity-button:hover {
    color: #007bff;
    background: none;
    border: none;
    text-decoration: none;
}

.activity-button:disabled {
    color: #ccc;
    background: none;
    border: none;
    text-decoration: none;
}

.card-header:first-child {
    border-radius: calc(0.625rem - 1px) calc(0.625rem - 1px) 0 0;
}

.card .btn-filled {
    border-color: #3d5170;
    background-color: #3d5170;
    color: #fff;
    margin: 10px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
}

.card .btn-filled:hover {
    border-color: #3d5170;
    background-color: #293445;
    color: #fff;
    margin: 10px;
}

.dashboard-stat-title {
    font-size: 1rem;
    color: #818ea3;
    text-transform: uppercase;
}

.dashboard-stat-value {
    font-size: 2rem;
    display: block;
    color: #126bba;
}

.form-radio-label {
    margin: 0px 10px 0px 10px;
}

/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
    color: #3d5174;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
    color: #333;
    text-shadow: none; /* Prevent inheritance from `body` */
    background-color: #fff;
    border: 0.05rem solid #fff;
}

.pwd-logo {
    height: 30px;
    padding-left: 20px;
    float: right;
}

/*
 * Header
 */
.masthead {
    margin-bottom: 2rem;
    color: #fff;
    text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
}

.masthead-brand {
    margin-bottom: 0;
    float: left;
}

.nav-masthead .nav-link {
    padding: 0.25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border-bottom: 0.25rem solid transparent;
    float: right;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
    margin-left: 1rem;
}

.nav-masthead .active {
    color: #fff;
    border-bottom-color: #fff;
}

.text-secondary {
    color: #fff !important;
}

.masthead-brand,
.nav-masthead,
.logo {
    width: 100%;
    align-self: center;
    justify-content: center;
}

@media (min-width: 448px) {
    .masthead-brand,
    .logo {
        align-self: auto;
    }
    .nav-masthead {
        justify-content: flex-end;
    }
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.p-3 {
    padding: 1rem !important;
}
.h-100 {
    height: 100% !important;
}

.subscription-panel {
    margin-top: 100px;
}

.checkout-notification {
    margin-top: 100px;
}

.stripe {
    display: block;
}

.paymentDetails {
    display: block;
    padding: 20px;
}

.settings-intro {
    display: block;
}

.settings-button {
    float: none;
    background: #3d5170;
    padding: 10px;
    color: #fff;
    min-width: 150px;
    border: 1px solid #3d5170;
    margin: 5px;
}

.settings-button:hover {
    background: #fff;
    color: #3d5170;
    border: 1px solid #3d5170;
}

.btn-settings {
    float: none;
    background: #3d5170;
    padding: 10px;
    color: #fff;
    min-width: 150px;
    border: 1px solid #3d5170;
    margin: 5px;
}

.btn-settings:hover {
    background: #fff;
    color: #3d5170;
    border: 1px solid #3d5170;
}

.btn-settings:disabled {
    background: #fff;
    color: #3d5170;
    cursor: default;
}

@media (max-width: 720px) {
    .btn-settings {
        width: 100%;
    }
}

.btn-settings-minor {
    float: none;
    background: none;
    padding: 10px;
    color: #3d5170;
    min-width: 150px;
    border: none;
    text-decoration: underline;
    margin: 5px;
}

.btn-settings-minor:hover {
    background: none;
    color: #3d5170;
    border: none;
    text-decoration: none;
}

.renewal-date {
    color: #908e8e;
    font-size: 1rem;
}

.renewal-date a {
    text-decoration: underline;
    color: #908e8e;
}

.renewal-date a:hover {
    text-decoration: none;
    color: #908e8e;
}

.split-form label {
    display: block;
}

.split-form input {
    display: block;
}

.fa-external-link-alt {
    color: #3d5170;
}

.navbar-brand a {
    color: #fff;
}

.authCallback-container {
    padding-top: 50px;
}

/* LOADING SPINNER */
.lds-container {
    width: 100%;
    text-align: center;
    clear: both;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #3d5170;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3d5170 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
